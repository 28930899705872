const list = [
	{
		name   : 'Anglais',
		file   : 'en',
		locale : [ 'en', 'en-en' ]
	},
	{
		name   : 'Français',
		file   : 'fr',
		locale : [ 'fr', 'fr-fr' ]
	},
	{
		name   : 'Allemand',
		file   : 'de',
		locale : [ 'de', 'de-de' ]
	},
	{
		name   : 'Portugais',
		file   : 'pt',
		locale : [ 'pt', 'pt-pt' ]
	},
	{
		name   : 'Espagnol',
		file   : 'es',
		locale : [ 'es', 'es-es' ]
	}

	/**
	{
		name   : 'Tchèque',
		file   : 'cs',
		locale : [ 'cs', 'cs-cs' ]
	},
	{
		name   : 'Chinois (Simplifié)',
		file   : 'cn',
		locale : [ 'zn', 'zn-zn', 'zn-cn' ]
	}, // hans
	{
		name   : 'Chinois (Traditionnel)',
		file   : 'tw',
		locale : [ 'zn-tw', 'zn-hk', 'zn-sg' ]
	}, // hant
	{
		name   : 'Croate',
		file   : 'hr',
		locale : [ 'hr', 'hr-hr' ]
	},
	{
		name   : 'Danois',
		file   : 'dk',
		locale : [ 'dk', 'dk-dk' ]
	},	
		{
		name   : 'Turc',
		file   : 'tr',
		locale : [ 'tr', 'tr-tr' ]
	},
	{
		name   : 'Ukrainien',
		file   : 'uk',
		locale : [ 'uk', 'uk-uk' ]
	},
	{
		name   : 'Roumain',
		file   : 'ro',
		locale : [ 'ro', 'ro-ro' ]
	},
	{
		name   : 'Russe',
		file   : 'ru',
		locale : [ 'ru', 'ru-ru' ]
	},
	{
		name   : 'Grèque',
		file   : 'el',
		locale : [ 'el', 'el-el' ]
	},
	{
		name   : 'Hindi',
		file   : 'hi',
		locale : [ 'hi', 'hi-hi' ]
	},
	{
		name   : 'Hongrois',
		file   : 'hu',
		locale : [ 'hu', 'hu-hu' ]
	},
	{
		name   : 'Italien',
		file   : 'it',
		locale : [ 'it', 'it-it' ]
	},
	{
		name   : 'Kazakh',
		file   : 'kk',
		locale : [ 'kk', 'kk-kz	' ]
	},
	{
		name   : 'Letton',
		file   : 'lv',
		locale : [ 'lv', 'lv-lv' ]
	},
	{
		name   : 'Norvégien',
		file   : 'nb',
		locale : [ 'nb', 'nb-no' ]
	},
	{
		name   : 'Polonais',
		file   : 'pl',
		locale : [ 'pl', 'pl-pl' ]
	},
	*/
];

export const detect = () =>
{
	const localeFull = (navigator.language ||
		(navigator as any).browserLanguage).toLowerCase();

	// const localeCountry = localeFull.split(/[-_]/)[0];

	// const localeRegion = localeFull.split(/[-_]/)[1] || null;

	return localeFull;
};

export const getList = () => list;

export interface ILocale {
  name: string;
  file: string;
  locale: string[];
  messages: any;
}

export const loadOne = (locale: string): ILocale =>
{
	let res: any = {};

	try
	{
		res = list.filter(
			// (item) => item.locale.includes(locale) || item.locale.includes(locale.split(/[-_]/)[0])
			(item) => item.locale.includes(locale)
		)[0];

		res.messages = require(`./${res.file}`);
	}
	catch
	{
		res = list.filter((item) => item.locale.includes('fr'))[0];

		res.messages = require(`./${res.file}`);
	}

	return res;
};

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRoomContext } from '../RoomContext';
import * as settingsActions from '../actions/settingsActions';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import randomString from 'random-string';
import { useLocation } from 'react-router-dom';

const DialogTitle = withStyles(() => ({
    root : {
        margin  : '0 auto',
        padding : 0
    }
}))(MuiDialogTitle);

const DialogContent = withStyles((theme) => ({
    root : {
        padding    : theme.spacing(2),
        paddingTop : theme.spacing(1)
    }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root : {
        margin  : 0,
        padding : theme.spacing(1)
    }
}))(MuiDialogActions);

const JoinDialog = ({
    roomClient,
    room,
    mediaPerms,
    setAudioMuted,
    setVideoMuted,
    displayName
}) => {
    const location = useLocation();

    displayName = displayName.trimLeft();

    const [ roomId ] = useState(
        decodeURIComponent(location.pathname.slice(1)) || randomString({ length: 8 }).toLowerCase()
    );

    let roomName = '';

    try {
        roomName = roomId.split('aambtaa')[0];
    } catch (e) {
        window.location.href = '/';
    }

    const _askForPerms = () => {
        if (mediaPerms.video || mediaPerms.audio) {
            navigator.mediaDevices.getUserMedia(mediaPerms);
        }
    };

    const handleJoin = () => {
        setAudioMuted(true);

        setVideoMuted(true);

        _askForPerms();

        const encodedRoomId = encodeURIComponent(roomId);

        roomClient.join({
            roomId    : encodedRoomId,
            roomName,
            joinVideo : mediaPerms.video,
            joinAudio : mediaPerms.audio
        });
    };

    const handleJoinUsingEnterKey = (event) => {
        if (event.key === 'Enter') document.getElementById('joinButton').click();
    };

	const [ checkState, setCheckState ] = useState(0);

	useEffect(() => {
		const permissions = navigator.mediaDevices.getUserMedia({ audio: true, video: true });

		permissions.then(() => {
			setCheckState(1);
		})
		.catch(() => {
			setCheckState(-1);
			console.info('Video or microphone not allowed');
		});
	}, []);

    return (
        <div>
            <Dialog onKeyDown={handleJoinUsingEnterKey} id='joinDialog' open>
                <DialogTitle disableTypography>
                    <Grid container direction='row' alignItems='center'>
                        <Grid item>
                            <div id='Title__Container'>
                                <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    aria-hidden='true'
                                    focusable='false'
                                    data-prefix='fas'
                                    data-icon='headset'
                                    className={'svg-inline--fa fa-headset fa-w-16'}
                                    role='img'
                                    viewBox='0 0 512 512'
                                >
                                    <path
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='currentColor'
                                        d='M192 208c0-17.67-14.33-32-32-32h-16c-35.35
										  0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67
										  0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65
										  64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32
										  32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83
										  0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208
										  208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93
										  42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48
										  48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42
										  118.83 398.82 0 256 0z'
                                    />
                                </svg>
                                <p>WEBITEACH</p>
                            </div>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <hr />
                    <DialogContentText
                        variant='h6'
                        gutterBottom
						id='subTitle'
                    >
                        <FormattedMessage id='lobby.welcome' defaultMessage={'Welcome'} />
                        {displayName}
                        <FormattedMessage id='lobby.inTheRoom' defaultMessage={'in the room'} />
                        {roomName}
                    </DialogContentText>
                    {!room.inLobby && room.overRoomLimit && (
                        <DialogContentText variant='h6' gutterBottom>
                            <FormattedMessage
                                id='room.overRoomLimit'
                                defaultMessage={'The room is full, retry after some time.'}
                            />
                        </DialogContentText>
                    )}
					{checkState === -1 &&
						<Grid container direction='row' alignItems='center'>
							<Grid item id='checkList'>
								<FormattedMessage
									id='lobby.checkList'
									defaultMessage={'Warning! You must authorize your microphone and camera to use Webiteach.'}
								/>
							</Grid>
							<Grid item>
								<img src='/images/webi-check.jpg' alt='Permission' />
							</Grid>
						</Grid>
					}
                </DialogContent>
                <DialogActions>
                    <Grid container direction='row' alignItems='flex-end'>
                        {/* JOIN/AUTH BUTTON */}
                        <Grid item>
                            <Button
                                onClick={handleJoin}
                                variant='contained'
                                color='primary'
                                id='joinButton'
                            >
                                <FormattedMessage id='label.join' defaultMessage='Join' />
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
};

JoinDialog.propTypes = {
    roomClient            : PropTypes.any.isRequired,
    room                  : PropTypes.object.isRequired,
    roomId                : PropTypes.string.isRequired,
    displayName           : PropTypes.string.isRequired,
    displayNameInProgress : PropTypes.bool.isRequired,
    loginEnabled          : PropTypes.bool.isRequired,
    changeDisplayName     : PropTypes.func.isRequired,
    mediaPerms            : PropTypes.object.isRequired,
    setAudioMuted         : PropTypes.func.isRequired,
    setVideoMuted         : PropTypes.func.isRequired,
    locale                : PropTypes.string.isRequired,
    localesList           : PropTypes.array.isRequired
};

const mapStateToProps = (state) => {
    return {
        room                  : state.room,
        mediaPerms            : state.settings.mediaPerms,
        displayName           : state.settings.displayName,
        displayNameInProgress : state.me.displayNameInProgress,
        loginEnabled          : state.me.loginEnabled,
        locale                : state.intl.locale,
        localesList           : state.intl.list
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeDisplayName : (displayName) => {
            dispatch(settingsActions.setDisplayName(displayName));
        },
        setAudioMuted : (flag) => {
            dispatch(settingsActions.setAudioMuted(flag));
        },
        setVideoMuted : (flag) => {
            dispatch(settingsActions.setVideoMuted(flag));
        }
    };
};

export default withRoomContext(
    connect(mapStateToProps, mapDispatchToProps, null, {
        areStatesEqual : (next, prev) => {
            return (
                prev.room.inLobby === next.room.inLobby &&
                prev.room.signInRequired === next.room.signInRequired &&
                prev.room.overRoomLimit === next.room.overRoomLimit &&
                prev.settings.displayName === next.settings.displayName &&
                prev.settings === next.settings &&
                prev.me.displayNameInProgress === next.me.displayNameInProgress &&
                prev.me.loginEnabled === next.me.loginEnabled &&
                prev.me.picture === next.me.picture &&
                prev.intl.locale === next.intl.locale &&
                prev.intl.localesList === next.intl.localesList
            );
        }
    })(JoinDialog)
);

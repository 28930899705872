// Recoding STATE
import BrowserRecorder from '../BrowserRecorder';

export const RECORDING_START = 'start';
export const RECORDING_STOP = 'stop';
export const RECORDING_PAUSE = 'pause';
export const RECORDING_RESUME = 'resume';
export const RECORDING_INIT = null;

export const setLocalRecordingState = (status) =>
    ({
        type    : 'SET_LOCAL_RECORDING_STATE',
        payload : { status }
    });
export const setLocalRecordingConsent = (agreed) =>
    ({
        type    : 'SET_LOCAL_RECORDING_CONSENT',
        payload : { agreed }
    });

export const recorder = new BrowserRecorder();

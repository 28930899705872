const initialState = {
	imAnimator                : false,
	idAnimator                : '',
	mobiteachStarted          : false,
	spotlightId               : null,
	listPeersAndWorkshop      : {},
	animatorStartedScreen     : false,
	showCurrentSpeakerInModal : true
};

const webi = (state = initialState, action) =>
{
	switch (action.type)
	{
		case 'SET_IM_ANIMATOR': {
			const { isAnimator } = action.payload;

			if (state && state.imAnimator === isAnimator) return state;

			return { ...state, imAnimator: isAnimator };
		}

		case 'SET_ID_ANIMATOR': {
			const { animatorId } = action.payload;

			if (state && state.idAnimator === animatorId) return state;

			return { ...state, idAnimator: animatorId };
		}

		case 'SET_LISTPEERS': {
			const { listPeers } = action.payload;

			if (state && state.listPeersAndWorkshop === listPeers) return state;

			return { ...state, listPeersAndWorkshop: listPeers };
		}

		case 'SET_MOBITEACHSTARTED': {
			const started = action.payload;

			if (state && state.mobiteachStarted === started) return state;

			return { ...state, mobiteachStarted: started };
		}

		case 'SET_SPOTLIGHTID': {
			const id = action.payload;

			if (state && state.spotlightId === id) return state;

			return { ...state, spotlightId: id };
		}

		case 'SET_ANIMATORSCREENSTARTED': {
			const started = action.payload;

			if (state && state.animatorStartedScreen === started) return state;

			return { ...state, animatorStartedScreen: started };
		}

		case 'SET_SHOWCURRENTSPEAKERINMODAL': {
			console.log('SET_SHOWCURRENTSPEAKERINMODAL', action.payload);
			const show = action.payload;

			if (state && state.showCurrentSpeakerInModal === show) return state;

			return { ...state, showCurrentSpeakerInModal: show };
		}

		default:
			return state;
	}
};

export default webi;

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = () => ({
    container : {
        display         : 'flex',
        alignItems      : 'center',
        justifyContent  : 'center',
        color           : '#111B1F',
        height          : '100%',
        backgroundColor : '#111B1F'
    },
    modal : {
        backgroundColor : 'white',
        padding         : '20px 50px',
        borderRadius    : '10px'
    },
    header : {
        display        : 'flex',
        alignItems     : 'center',
        justifyContent : 'center',
        fontWeight     : 'bold',
        textTransform  : 'uppercase',
        fontSize       : '2rem',
        color          : '#111b1f'
    },

    icon : {
        height      : '50px',
        marginRight : '20px',
        color       : '#111b1f'
    },

    title : {
        textAlign : 'center',
        color     : 'black'
    },

    p : {
        textAlign  : 'center',
        color      : '#EF5E7D',
        fontWeight : 'bold'
    }
});

const Index = ({ classes }) => {
    return (
        <div className={classes.container}>
            <div className={classes.modal}>
                <header className={classes.header}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                        focusable='false'
                        data-prefix='fas'
                        data-icon='headset'
                        className={classes.icon}
                        role='img'
                        viewBox='0 0 512 512'
                    >
                        <path
                            fill='currentColor'
                            d='M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z'
                        />
                    </svg>
                    <p>WEBITEACH</p>
                </header>
                <hr />
                <section>
                    <h2 className={classes.title}>Hello World</h2>
                </section>
            </div>
        </div>
    );
};

Index.prototype = {
    classes : PropTypes.object.isRequired
};

export default (withStyles(styles, { withTheme: true })(Index));
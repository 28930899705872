export const setImAnimator = ({ isAnimator }) => ({
	type    : 'SET_IM_ANIMATOR',
	payload : { isAnimator }
});

export const setIdAnimator = ({ animatorId }) => ({
	type    : 'SET_ID_ANIMATOR',
	payload : { animatorId }
});

export const setListPeers = ({ listPeers }) => ({
	type    : 'SET_LISTPEERS',
	payload : { listPeers }
});

export const setMobiteachStarted = (started) => ({
	type    : 'SET_MOBITEACHSTARTED',
	payload : started
});

export const setSpotLightID = (id) => ({
	type    : 'SET_SPOTLIGHTID',
	payload : id
});

export const setAnimatorStartedScreen = (started) => ({
	type    : 'SET_ANIMATORSCREENSTARTED',
	payload : started
});

export const setShowCurrentSpeakerInModal = (show) => ({
	type    : 'SET_SHOWCURRENTSPEAKERINMODAL',
	payload : show
});

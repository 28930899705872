import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

const AnimatorEndSession = () => {
    useEffect(() => {
        const timer = setTimeout(() => window.close(), 3000);

        return () => clearTimeout(timer);
    }, []);

    const intl = useIntl();

    console.log('intl', intl);

    return (
        <div id='ExitPage'>
            <div id='ExitPage__Container'>
                <div id='ExitPage__Container__Header'>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        aria-hidden='true'
                        focusable='false'
                        data-prefix='fas'
                        data-icon='headset'
                        className={'svg-inline--fa fa-headset fa-w-16'}
                        role='img'
                        viewBox='0 0 512 512'
                    >
                        <path
                            fill='currentColor'
                            d='M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z'
                        />
                    </svg>
                    <span>
                        {intl.formatMessage({
                            id             : 'workshop.endwebisession',
                            defaultMessage : 'Fin de la session Webiteach'
                        })}
                    </span>
                </div>
                <p>
                    <FormattedMessage id='workshop.closetabwebi' defaultMessage='Vous pouvez maintenant fermer cet onglet ou il se fermera dans 3 secondes' />
                </p>
                <p>
                    <FormattedMessage id='workshop.goodbye' defaultMessage='A bientôt' />
                </p>
            </div>
        </div>
    );
};

export default AnimatorEndSession;

import domready from 'domready';
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import isElectron from 'is-electron';
import { createIntl } from 'react-intl';
import { IntlProvider } from 'react-intl-redux';
import { Route, HashRouter, BrowserRouter, Switch } from 'react-router-dom';
import Logger from './Logger';
import debug from 'debug';
import RoomClient from './RoomClient';
import RoomContext from './RoomContext';
import deviceInfo from './deviceInfo';
import * as meActions from './actions/meActions';
import * as webiActions from './actions/webiActions';
import UnsupportedBrowser from './components/UnsupportedBrowser';
import JoinDialog from './components/JoinDialog';
import LoginDialog from './components/AccessControl/LoginDialog';
import LoadingView from './components/Loader/LoadingView';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from './store';
import { SnackbarProvider } from 'notistack';
import * as serviceWorker from './serviceWorker';
import { LazyPreload } from './components/Loader/LazyPreload';
import { detectDevice } from 'mediasoup-client';
import { recorder } from './actions/recorderActions';
import DialogInfos from './components/Infos';
import Dialog404 from './components/404';
import axios from 'axios';
import './assets/scss/main.scss';
import './index.css';
import { config } from './config';
import AnimatorEndSession from './components/AnimatorEndSession/index';

const App = LazyPreload(() => import(/* webpackChunkName: "app" */ './components/App'));

// const cache = createIntlCache();

const supportedBrowsers =
{
	'windows' : {
		'internet explorer' : '>12',
		'microsoft edge'    : '>18'
	},
	'safari'                       : '>12',
	'firefox'                      : '>=60',
	'chrome'                       : '>=74',
	'chromium'                     : '>=74',
	'opera'                        : '>=62',
	'samsung internet for android' : '>=11.1.1.52'
};

const intl = createIntl({ locale: 'fr', defaultLocale: 'fr' });

recorder.intl = intl;

if (process.env.REACT_APP_DEBUG === '*' || process.env.NODE_ENV !== 'production')
{
	debug.enable('* -engine* -socket* -RIE* *WARN* *ERROR*');
}

const logger = new Logger();

let roomClient;

RoomClient.init({ store });

// Change default color for theme
config.theme.overrides.MuiBadge.colorPrimary.backgroundColor = '#A5DD43';
config.theme.overrides.MuiButton.containedSecondary.backgroundColor = '#EF5E7D';
config.theme.overrides.MuiFab.secondary.backgroundColor = '#EF5E7D';
config.theme.overrides.MuiFab.primary.backgroundColor = '#A5DD43';
config.theme.overrides.MuiButton.containedPrimary.backgroundColor = '#A5DD43';

const theme = createMuiTheme(config.theme);

let Router;

if (isElectron())
	Router = HashRouter;
else
	Router = BrowserRouter;

domready(() =>
{
	logger.debug('DOM ready');

	run();
});

const redirectInfos = () =>
{
	window.location.href = `${window.origin}/infos`;
};

const redirect404 = () =>
{
	window.location.href = `${window.origin}/404`;
};

function run()
{
	logger.debug('run() [environment:%s]', process.env.NODE_ENV);

	const urlParser = new URL(window.location);
	const parameters = urlParser.searchParams;
	const tokenParam = parameters.get('t');
	const isInfos = urlParser.pathname === '/infos';
	const is404Page = urlParser.pathname === '/404';
	const isExitPage = urlParser.pathname === '/exit';

	const { pathname } = window.location;

	let basePath = pathname.substring(0, pathname.lastIndexOf('/'));

	if (!basePath)
		basePath = '/';

	if (!isInfos && !is404Page && !isExitPage && !tokenParam) return redirectInfos();
	if (!isInfos && !is404Page && !isExitPage)
	{
		const decodedToken = window.atob(decodeURIComponent(tokenParam));

		const token = JSON.parse(decodedToken);

		if (Object.keys(token).length < 5) return redirect404();

		const peerId = token.peerId ? token.peerId : null;
		const isAnimator = token.isAnimator ? token.isAnimator : false;
		const displayName = token.displayName ? token.displayName : 'Anonymous';
		const email = token.email ? token.email : null;
		const instanceMobi = token.instanceMobi ? token.instanceMobi : null;
		const parentId = token.parentId ? token.parentId : '';
		const moderatorId = isAnimator ? peerId : token.moderatorId;

		const animatorId = isAnimator ? peerId : moderatorId;
		const tokenMobi = isAnimator ? `Bearer ${token.tokenMobi}` : '';
		const accessCode = parameters.get('code');
		const produce = parameters.get('produce') !== 'false';
		const forceTcp = parameters.get('forceTcp') === 'true';
		const muted = parameters.get('muted') === 'true';

		// Set default headers and base URL for axios if isAnimator
		if (isAnimator)
		{
			axios.defaults.headers.common['webi-token'] = tokenMobi;
			axios.defaults.baseURL = instanceMobi;
		}

		// Get current device.
		const device = deviceInfo();

		let unsupportedBrowser = false;

		let webrtcUnavailable = false;

		if (detectDevice() === undefined)
		{
			logger.error('Your browser is not supported [deviceInfo:"%o"]', device);

			unsupportedBrowser = true;
		}
		else if (
			navigator.mediaDevices === undefined ||
			navigator.mediaDevices.getUserMedia === undefined ||
			window.RTCPeerConnection === undefined
		)
		{
			logger.error('Your browser is not supported [deviceInfo:"%o"]', device);

			webrtcUnavailable = true;
		}
		else if (
			!device.bowser.satisfies(
				window.config.supportedBrowsers || supportedBrowsers
			)
		)
		{
			logger.error(
				'Your browser is not supported [deviceInfo:"%o"]',
				device
			);

			unsupportedBrowser = true;
		}
		else
		{
			logger.debug('Your browser is supported [deviceInfo:"%o"]', device);
		}

		if (unsupportedBrowser || webrtcUnavailable)
		{
			render(
				<MuiThemeProvider theme={theme}>
					<IntlProvider value={intl}>
						<UnsupportedBrowser
							webrtcUnavailable={webrtcUnavailable}
							platform={device.platform}
						/>
					</IntlProvider>
				</MuiThemeProvider>,
				document.getElementById('edumeet')
			);

			return;
		}

		store.dispatch(
			meActions.setMe({
				peerId,
				email,
				loginEnabled : window.config.loginEnabled
			})
		);

		store.dispatch(
			webiActions.setImAnimator({ isAnimator })
		);

		store.dispatch(
			webiActions.setIdAnimator({ animatorId })
		);

		roomClient = new RoomClient(
			{
				peerId,
				accessCode,
				device,
				produce,
				forceTcp,
				displayName,
				muted,
				basePath,
				isAnimator,
				animatorId,
				parentId,
				instanceMobi,
				email,
				tokenMobi
			});

		global.CLIENT = roomClient;
	}

	render(
		<Provider store={store}>
			<MuiThemeProvider theme={theme}>
				<IntlProvider value={intl}>
					<PersistGate loading={<LoadingView />} persistor={persistor}>
						<RoomContext.Provider value={roomClient}>
							<SnackbarProvider>
								<Router basename={basePath}>
									<Suspense fallback={<LoadingView />}>
										<React.Fragment>
											<Switch>
												<Route exact path='/' component={JoinDialog} />
												<Route exact path='/infos' component={DialogInfos} />
												<Route exact path='/exit' component={AnimatorEndSession} />
												<Route exact path='/404' component={Dialog404} />
												<Route exact path='/login_dialog' component={LoginDialog} />
												<Route path='/:id' component={App} />
											</Switch>
										</React.Fragment>
									</Suspense>
								</Router>
							</SnackbarProvider>
						</RoomContext.Provider>
					</PersistGate>
				</IntlProvider>
			</MuiThemeProvider>
		</Provider>,
		document.getElementById('edumeet')
	);
}

serviceWorker.unregister();
